import React from 'react';
import { Link, graphql } from 'gatsby';
import { v4 } from 'uuid';
import Layout from '../../components/Layout';
import Image from '../../components/Image';
import BackgroundImage from '../../components/BackgroundImage';
import { ImageOverlay } from '../../utils';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const BlogPageTemplate = ({ image, title, blogPosts, helmet }) => {
  const mappedBlogPosts = blogPosts.map((post) => (
    <div className='column is-4 blog-post' key={v4()}>
      <Link to={post.node.fields.slug} className='hover-zoom'>
        <Image
          imageInfo={{ image: post.node.frontmatter.heroImageObject.image }}
          classes='property-roll-image'
          animationClass='hover-zoom'
        />
        <h4 className='title blog-title'>{post.node.frontmatter.title}</h4>
        <div>{post.node.excerpt}</div>
      </Link>
    </div>
  ));
  return (
    <div className='page-container container content'>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      <div className='container content'>
        <div className='columns is-multiline'>{mappedBlogPosts}</div>
      </div>
    </div>
  );
};

const BlogPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const blogPosts = data.allMarkdownRemark.edges;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <BlogPageTemplate
        location={location}
        image={frontmatter.heroImageObject}
        title={frontmatter.blogTitle}
        blogPosts={blogPosts}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query blogPageQueryJP {
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page-jp" } }) {
      frontmatter {
        blogTitle
        heroImageObject {
          overlay
          image
        }
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post-jp" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            heroImageObject {
              image
            }
          }
        }
      }
    }
  }
`;
